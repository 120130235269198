// libraries
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

// custom components
import AvailabilityForm from './AvailabilityForm';

// hooks
import useModal from 'hooks/useModal';
import useProviderPaymentInfo from 'hooks/useProviderPaymentInfo';
import { useCalendar } from 'hooks/useCalendar';

// services
import { convertTimeSlotsToDataModel } from './services';

// selectors
import { selectUser } from 'modules/User/selectors';

// api
import api from './api';

// types
import { ConsultationTypes } from 'types';

// utils
import { setPrevTimeSlotsByConsultationType, getNumberOfPrevTimeSlots } from './utils';
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

// styles
import colors from 'theme/colors';
import { getValue } from 'utils/getValue';

// constants
import { TIME_ZONE_OPTIONS } from './constants';

const AvailabilityContainer = (): JSX.Element => {
  const history = useHistory();

  const { handleModalOpen } = useModal();

  const { calendarMultipleValues, setCalendarMultipleValues } = useCalendar();

  const {
    userId,
    token: { accessToken },
  } = useSelector(selectUser);

  const { providerPaymentInfo } = useProviderPaymentInfo(accessToken);

  const [prevAvailabilities, setPrevAvailabilities] = useState([]);

  const isTodaySelected = useMemo(
    () => !!calendarMultipleValues.find((calendarDay: any) => moment(calendarDay).isSame(new Date(), 'day')),
    [calendarMultipleValues],
  );

  const prevAvailabilitiesDates = useMemo(() => prevAvailabilities.map(({ date }) => new Date(date)), [
    prevAvailabilities,
  ]);

  const modifiers = useMemo(
    () => ({
      defaultAvailabilities: prevAvailabilitiesDates,
    }),
    [prevAvailabilitiesDates],
  );

  const modifiersStyles = useMemo(
    () => ({
      defaultAvailabilities: {
        color: colors.primary,
        backgroundColor: colors.pampas,
      },
    }),
    [],
  );
  const [isPrevAvailabilitiesDateSelected, setIsPrevAvailabilitiesDateSelected]: any = useState(false);

  const [numberOfInPersonPrevTimeSlots, setNumberOfInPersonPrevTimeSlots] = useState([]);

  const [numberOfOnLinePrevTimeSlots, setNumberOfOnLinePrevTimeSlots] = useState([]);

  const [numberOfFreePrevTimeSlots, setNumberOfFreePrevTimeSlots] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const prevAvailability = await api.getPrevAvailability(accessToken);

        setPrevAvailabilities(prevAvailability.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [accessToken]);

  useEffect(() => {
    const selectedPrevAvailabilitiesDate = prevAvailabilitiesDates.find(
      (prevAvailabilitiesDate: any) =>
        !!calendarMultipleValues.find((calendarValue: any) =>
          moment(prevAvailabilitiesDate).isSame(calendarValue, 'day'),
        ),
    );

    if (selectedPrevAvailabilitiesDate) {
      const prevAvailabilitiesInfoBySelectedDate = prevAvailabilities.find(({ date }: any) =>
        moment(date).isSame(selectedPrevAvailabilitiesDate, 'day'),
      );
      if (prevAvailabilitiesInfoBySelectedDate) {
        const prevAvailabilitiesInfoBySelectedDateSlots = getValue('slots', prevAvailabilitiesInfoBySelectedDate);

        if (prevAvailabilitiesInfoBySelectedDateSlots['inPerson']) {
          setNumberOfInPersonPrevTimeSlots(
            getNumberOfPrevTimeSlots(prevAvailabilitiesInfoBySelectedDateSlots['inPerson']),
          );
        }
        if (prevAvailabilitiesInfoBySelectedDateSlots['online']) {
          setNumberOfOnLinePrevTimeSlots(getNumberOfPrevTimeSlots(prevAvailabilitiesInfoBySelectedDateSlots['online']));
        }
        if (prevAvailabilitiesInfoBySelectedDateSlots['freeConsultation']) {
          setNumberOfFreePrevTimeSlots(
            getNumberOfPrevTimeSlots(prevAvailabilitiesInfoBySelectedDateSlots['freeConsultation']),
          );
        }
      }
    } else {
      setNumberOfInPersonPrevTimeSlots([]);
      setNumberOfOnLinePrevTimeSlots([]);
      setNumberOfFreePrevTimeSlots([]);
    }
  }, [calendarMultipleValues, prevAvailabilities, prevAvailabilitiesDates]);

  const getDisabledDays = useCallback(
    (day: Date) => {
      if (isTodaySelected) {
        return !moment(day).isSame(new Date(), 'day');
      }

      const selectedPrevAvailabilitiesDate = prevAvailabilitiesDates.find(
        (prevAvailabilitiesDate) =>
          !!calendarMultipleValues.find((calendarValue: any) =>
            moment(prevAvailabilitiesDate).isSame(calendarValue, 'day'),
          ),
      );

      if (selectedPrevAvailabilitiesDate) {
        return !moment(day).isSame(selectedPrevAvailabilitiesDate, 'day');
      }

      return moment(moment(new Date()).startOf('day')).isAfter(moment(day).startOf('day'));
    },
    [calendarMultipleValues, isTodaySelected, prevAvailabilitiesDates],
  );

  const handleCalendarChange = useCallback(
    (change: any, reset: any) => (day: Date, { selected, disabled, today }: any) => {
      if (disabled) {
        return;
      }

      const selectedPrevAvailabilitiesDate = prevAvailabilitiesDates.find((prevAvailabilitiesDate) =>
        moment(prevAvailabilitiesDate).isSame(day, 'day'),
      );

      if (selectedPrevAvailabilitiesDate) {
        setIsPrevAvailabilitiesDateSelected(moment(day).isSame(selectedPrevAvailabilitiesDate, 'day'));
      } else {
        setIsPrevAvailabilitiesDateSelected(false);
      }

      if (selectedPrevAvailabilitiesDate) {
        const prevAvailabilitiesInfoBySelectedDate = prevAvailabilities.find(({ date }) =>
          moment(date).isSame(selectedPrevAvailabilitiesDate, 'day'),
        );

        if (prevAvailabilitiesInfoBySelectedDate) {
          const timeZone = getValue('timeZone', prevAvailabilitiesInfoBySelectedDate);

          const defaultTimeZoneOption = TIME_ZONE_OPTIONS.find(
            (timeZoneOption: any) => timeZoneOption.value === timeZone,
          );

          change('timeZone', defaultTimeZoneOption);

          const prevAvailabilitiesInfoBySelectedDateSlots = getValue('slots', prevAvailabilitiesInfoBySelectedDate);

          if (prevAvailabilitiesInfoBySelectedDateSlots['inPerson']) {
            setPrevTimeSlotsByConsultationType(
              prevAvailabilitiesInfoBySelectedDateSlots['inPerson'],
              change,
              'InPerson',
            );
          }
          if (prevAvailabilitiesInfoBySelectedDateSlots['online']) {
            setPrevTimeSlotsByConsultationType(prevAvailabilitiesInfoBySelectedDateSlots['online'], change, 'Online');
          }
          if (prevAvailabilitiesInfoBySelectedDateSlots['freeConsultation']) {
            setPrevTimeSlotsByConsultationType(
              prevAvailabilitiesInfoBySelectedDateSlots['freeConsultation'],
              change,
              'FreeConsultation',
            );
          }
        }

        setCalendarMultipleValues((state: Date[]) => {
          if (selected) {
            reset();
            return state.filter((calendarValue: Date) => {
              return !moment(calendarValue).isSame(selectedPrevAvailabilitiesDate, 'day');
            });
          } else {
            return [selectedPrevAvailabilitiesDate];
          }
        });
      }

      if (today) {
        setCalendarMultipleValues((state: Date[]) =>
          selected ? state.filter((calendarValue: Date) => !moment(calendarValue).isSame(day, 'day')) : [day],
        );
      }

      if (!selectedPrevAvailabilitiesDate && !today) {
        setCalendarMultipleValues((state: Date[]) =>
          selected ? state.filter((calendarValue: Date) => !moment(calendarValue).isSame(day, 'day')) : [...state, day],
        );
      }
    },
    [prevAvailabilities, prevAvailabilitiesDates, setCalendarMultipleValues],
  );

  const onSubmit = useCallback(
    async (values: any) => {
      const timeSlots = !!calendarMultipleValues.length
        ? convertTimeSlotsToDataModel(calendarMultipleValues, values)
        : [];

      const paidTimeSlots = timeSlots.find(
        (propduct: any) => propduct.consultationType !== ConsultationTypes.FreeConsultation,
      );

      const selectedPrevAvailabilitiesDate = prevAvailabilitiesDates.find(
        (prevAvailabilitiesDate: any) =>
          !!calendarMultipleValues.find((calendarValue: any) =>
            moment(prevAvailabilitiesDate).isSame(calendarValue, 'day'),
          ),
      );

      if (!!calendarMultipleValues.length) {
        if (selectedPrevAvailabilitiesDate) {
          if (!!timeSlots.length) {
            await api.updateAvailableTimeSlots(userId, timeSlots, accessToken);
          } else {
            const convertedDate = moment(calendarMultipleValues[0]).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss');

            await api.deleteAvailableTimeSlots(convertedDate, values?.timeZone?.value, accessToken);
          }
        } else {
          if (!!timeSlots.length) {
            await api.postAvailableTimeSlots(userId, timeSlots, accessToken);
          } else {
            throw new Error('Please select time');
          }
        }
      } else {
        throw new Error('Please select day');
      }

      if (paidTimeSlots && !providerPaymentInfo.payoutsEnabled) {
        handleModalOpen('notificationModal', {
          description: 'Your availability will remain hidden until you connect your bank account to receive payment.',
          buttonLabel: 'Ok',
        })();
      }

      setCalendarMultipleValues([]);

      history.push('/provider/dashboard/appointments/availability/complete');

      window.scroll(0, 0);
    },
    [
      calendarMultipleValues,
      prevAvailabilitiesDates,
      providerPaymentInfo.payoutsEnabled,
      setCalendarMultipleValues,
      history,
      userId,
      accessToken,
      handleModalOpen,
    ],
  );

  const validationSchema = useMemo(
    () => ({
      timeZone: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  return (
    <AvailabilityForm
      onSubmit={onSubmit}
      formValidation={formValidation}
      isTodaySelected={isTodaySelected}
      calendarValues={calendarMultipleValues}
      disabledDays={getDisabledDays}
      calendarModifiers={modifiers}
      calendarModifiersStyles={modifiersStyles}
      onCalendarChange={handleCalendarChange}
      isPrevAvailabilitiesDateSelected={isPrevAvailabilitiesDateSelected}
      numberOfInPersonPrevTimeSlots={numberOfInPersonPrevTimeSlots}
      numberOfOnLinePrevTimeSlots={numberOfOnLinePrevTimeSlots}
      numberOfFreePrevTimeSlots={numberOfFreePrevTimeSlots}
    />
  );
};

export default AvailabilityContainer;
