// libraries
import React, { useEffect, useMemo } from 'react';
import { Switch, useLocation, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// custom components
import Modal from 'components/Modals';
import CommonRoutes from './Common';
import ClientRoutes from './Client';

// routes
import ProviderBasicOrProRoutes from './ProviderBasicOrPro';

// selectors
import { selectModalTitle } from '../components/Modals/selectors';
import { selectUser } from 'modules/User/selectors';

// assets
import providerCriteriaThunk from 'modules/ProviderCriteria/thunk';

// constants
import { REDIRECTS } from 'constants/redirects';

const Routes = (): JSX.Element => {
  const location = useLocation();

  const dispatch = useDispatch();

  const modal = useSelector(selectModalTitle);

  const { isLoggedIn, role } = useSelector(selectUser);

  useEffect(() => {
    dispatch(providerCriteriaThunk());
  }, [dispatch]);

  const urlFromRedirects = useMemo(
    () =>
      REDIRECTS.find((redirect: { currentURL: string; newURL: string }) => redirect.currentURL === location.pathname),
    [location.pathname],
  );

  if (urlFromRedirects?.newURL.includes('https://classes.wearerobyn.co')) {
    window.location.href = urlFromRedirects?.newURL;
  }

  return (
    <>
      <Switch>
        {urlFromRedirects && !urlFromRedirects?.newURL.includes('https://classes.wearerobyn.co') && (
          <Redirect from={urlFromRedirects?.currentURL} to={urlFromRedirects?.newURL} />
        )}
        <>
          <CommonRoutes />

          <ProviderBasicOrProRoutes userRole={role} isLoggedIn={isLoggedIn} />

          <ClientRoutes isLoggedIn={isLoggedIn} />
        </>
      </Switch>
      {modal && <Modal />}
    </>
  );
};

export default Routes;
