export enum ProductSectionTypes {
  ProductsOffered,
  InputAvailability,
  UpcomingAppointments,
  Payment,
}

export const typeOfAppointmentSections = {
  'products-offered': ProductSectionTypes.ProductsOffered,
  'payment-info': ProductSectionTypes.Payment,
};
