/* eslint-disable */

// libraries
import React, { useMemo } from 'react';
import Select, { components } from 'react-select';
import { Field } from 'react-final-form';
import Icon from '../../Icon';
import colors from 'theme/colors';

interface CustomSelectProps {
  name: string;
  options: any;
  customStyles?: any;
  isMulti?: boolean;
  dropdownIndicatorColor?: string;
  onSelectChange?: VoidFunction;
  isDisabled?: boolean;
  dropdownIndicatorWidth?: string;
  dropdownIndicatorHeight?: string;
}

const DropdownIndicator = (props: any): JSX.Element => (
  <components.DropdownIndicator {...props}>
    <Icon
      type="downArrow"
      color={props.iconColor ? props.iconColor : colors.white}
      width={props.width || '18px'}
      height={props.height || '13px'}
    />
  </components.DropdownIndicator>
);

const CustomSelect = ({
  name,
  options,
  customStyles,
  dropdownIndicatorColor,
  onSelectChange,
  isDisabled,
  dropdownIndicatorWidth,
  dropdownIndicatorHeight,
  // if you want to use custom onChange you must use form change method in you custom handler for changing select value!
  ...props
}: CustomSelectProps & any): JSX.Element => {
  const selectComponents = useMemo(
    () => ({
      DropdownIndicator: (props: any) => (
        <DropdownIndicator
          width={dropdownIndicatorWidth}
          height={dropdownIndicatorHeight}
          iconColor={dropdownIndicatorColor}
          {...props}
        />
      ),
    }),
    [],
  );

  return (
    <Field name={name}>
      {({ input: { onChange, ...restInput }, ...rest }: any): JSX.Element => (
        <Select
          options={options}
          styles={customStyles}
          components={selectComponents}
          isSearchable={false}
          onChange={(value: any) => {
            if (onSelectChange) {
              onSelectChange(value);
            } else {
              onChange(value);
            }
          }}
          isDisabled={isDisabled}
          {...restInput}
          {...rest}
          {...props}
        />
      )}
    </Field>
  );
};

export default CustomSelect;
