// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Textarea from 'components/Form/Textarea';
import Select from 'components/Form/Select/Select';
import Loader from 'components/Loader';
import Checkbox from 'components/Form/Checkbox';

// styled components
import { SubmitButton, Error, Flex } from 'shared/styledComponents';
import { Title } from 'shared/typography';
import { TextStyles } from 'shared/styles';
import {
  Wrapper,
  CheckboxWrapper,
  CheckboxesWrapper,
  CheckboxLabel,
  CheckboxWrapperAndPriceInputWrapper,
} from '../Appointments/ProductsOffered/ProductsOfferedForm';

// types
import { ProviderCriteria } from 'modules/ProviderCriteria/types';
import { ProviderProfileInfo } from './types';
import { ConsultationTypes } from 'types';
import { OfferedProduct } from 'pages/ProviderProfile/types';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

// constants
import { STATES_OPTIONS } from 'modules/SignUpWithFooterContactForms/ProviderSignUp/constants';
import { UserRoles } from 'constants/user';

// styles
import { SelectStyles } from 'components/Form/Select/defaultSelectStyles';
import devices from 'theme/devices';
import colors from 'theme/colors';

// utils
import { getValue } from 'utils/getValue';

const StepsWrapper = styled.div`
  margin-bottom: 50px;

  @media ${devices.mobile} {
    margin-bottom: 60px;
  }
`;

const StepWrapper = styled.div`
  border-bottom: 1px solid ${colors.alto};
  margin-bottom: 50px;
  padding-bottom: 50px;

  :last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media ${devices.mobile} {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`;

const StepHeading = styled(Title)`
  margin-bottom: 35px;
  line-height: 1;
  cursor: pointer;

  @media ${devices.mobile} {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 23px;
  }
`;

const SubHeading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  color: ${colors.lightBrown};
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
    line-height: 19px;
  }
`;

const PairedInputs = styled(Flex)`
  justify-content: space-between;
  max-width: 565px;
  margin-bottom: 20px;

  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const InputsWrapper = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  max-width: 270px;
  border: 1px solid ${colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  margin-right: 10px;
  outline: none;

  :last-child {
    margin-right: 0;
  }

  ::placeholder {
    color: ${colors.lightBrown};
  }

  @media ${devices.mobile} {
    max-width: 310px;
    margin-right: 0;
    margin-bottom: 15px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const AddressInput = styled(StyledInput)`
  margin-bottom: 20px;
`;

const SuccessTitle = styled(Title)`
  margin-top: 20px;
`;

const CriteriaList = styled(Flex)`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 10px;
  grid-row-gap: 12px;
  max-width: 800px;

  @media ${devices.mobile} {
    max-width: 310px;
    grid-template-columns: auto auto;
  }
`;

const CriteriaItem = styled(Title)<any>(
  ({ isSelected, theme: { colors } }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    background-color: ${isSelected ? colors.bismark : colors.pampas};
    color: ${isSelected ? colors.pampas : colors.bismark};
    border-radius: 20px;
    list-style: none;
    cursor: pointer;

    :hover {
      background-color: ${isSelected ? colors.bismark : colors.pampas};
      opacity: ${isSelected ? 1 : 0.5};
      color: ${isSelected ? colors.pampas : colors.bismark};
    }

    @media ${devices.mobile} {
      padding: 12px 6px;
    }
  `,
);

export const StyledTextarea = styled(Textarea)(
  ({ theme: { colors, fonts } }) => `
    width: 100%;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid ${colors.alto};
    background-color: ${colors.white};
    color: ${colors.lightBrown};
    font-family: ${fonts.wigrumLight};
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    outline: none;
    resize: none;

    @media ${devices.mobile} {
      margin-bottom: 20px;
    }
  `,
);

const UploadInput = styled(Input)`
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
`;

const UploadLabel = styled.label(
  ({ theme: { colors, fonts } }) => `
    display: inline-block;
    min-width: 225px;
    padding: 17px 19px;
    text-align: center;
    font-family: ${fonts.wigrumRegular};
    font-size: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    background-color: ${colors.bismark};
    color: ${colors.white};
    cursor: pointer;
    
    @media ${devices.mobile} {
      margin-top: 35px;
    }
  `,
);

const UploadWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: -75px;

  @media ${devices.mobile} {
    position: static;
  }
`;

const UploadHeading = styled(Title)`
  display: none;

  @media ${devices.mobile} {
    display: block;
    font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
    color: ${colors.lightBrown};
    margin-bottom: 30px;
    line-height: 19px;
  }
`;

const Heading = styled(Title)`
  margin-bottom: 50px;
`;

interface EditProfileFormProps {
  onSubmit: (values: any) => void;
  formValidation: (values: any) => void;
  initialValues: any;
  onChangeUploadInput: any;
  isProviderProfileInfoLoading: boolean;
  providerProfileInfo: ProviderProfileInfo;
  services: ProviderCriteria[];
  stageOptions: any;
  credentialsOptions: any;
  uploadedImageName: string | undefined;
  specializations: ProviderCriteria[];
  onServiceItemClick: (selectedService: ProviderCriteria) => () => void;
  onSpecializationItemClick: (selectedSpecialization: ProviderCriteria) => () => void;
  isProductsOfferedLoading: boolean;
  productsOffered: OfferedProduct[] | undefined;
  onProductOfferedChange: (selectedProduct: OfferedProduct) => () => void;
  useRole: string;
}

const EditProfileForm = ({
  onSubmit,
  formValidation,
  initialValues,
  isProviderProfileInfoLoading,
  providerProfileInfo,
  services,
  specializations,
  stageOptions,
  credentialsOptions,
  onServiceItemClick,
  onSpecializationItemClick,
  onChangeUploadInput,
  uploadedImageName,
  isProductsOfferedLoading,
  productsOffered,
  onProductOfferedChange,
  useRole,
}: EditProfileFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} initialValues={initialValues} validate={formValidation}>
    {({ submitting, submitSucceeded, submitFailed, submitErrors, hasValidationErrors, errors }): JSX.Element => (
      <>
        <StepsWrapper>
          <StepWrapper>
            <StepHeading>Edit Profile</StepHeading>
            <UploadHeading size="medium">
              For your profile picture which is your headshot the recommended size is 500 x 500px. (PNG, JPG format)
            </UploadHeading>
            <PairedInputs>
              <StyledInput name="firstName" type="text" placeholder="First Name" size="small" />
              <StyledInput name="lastName" type="text" placeholder="Last Name" size="small" />
            </PairedInputs>
            <UploadWrapper>
              <UploadInput name="file" onChange={onChangeUploadInput} type="file" id="file" />
              <UploadLabel htmlFor="file">{uploadedImageName || 'Choose a new image'}</UploadLabel>
            </UploadWrapper>
          </StepWrapper>
          <StepWrapper>
            <StepHeading>Stages</StepHeading>
            <Select
              name="stages"
              options={stageOptions}
              customStyles={SelectStyles}
              placeholder={<div>Stage</div>}
              dropdownIndicatorColor={colors.bismark}
              isMulti
            />
          </StepWrapper>
          <StepWrapper>
            <StepHeading>Services Offered</StepHeading>
            <SubHeading size="medium">Select up to 3</SubHeading>
            {isProviderProfileInfoLoading ? (
              <Loader />
            ) : (
              <CriteriaList as="ul">
                {!!services.length &&
                  services.map((service: ProviderCriteria) => (
                    <CriteriaItem
                      size="small"
                      as="li"
                      key={service.id}
                      isSelected={!!providerProfileInfo.services.find(({ id }: any) => id === service.id)}
                      onClick={onServiceItemClick(service)}
                    >
                      {service.value}
                    </CriteriaItem>
                  ))}
              </CriteriaList>
            )}
          </StepWrapper>
          {useRole === UserRoles.PROVIDER_BASIC && (
            <StepWrapper>
              <Wrapper>
                <Heading>Products Offered</Heading>
                <SubHeading size="medium">
                  Select which products you’d like to offer and assign pricing to appointment types.
                </SubHeading>
                {isProductsOfferedLoading ? (
                  <Loader />
                ) : (
                  <CheckboxesWrapper>
                    {!!productsOffered?.length &&
                      productsOffered.map((product: any) => (
                        <CheckboxWrapperAndPriceInputWrapper key={product.id}>
                          <CheckboxWrapper>
                            <Checkbox
                              name={ConsultationTypes[product.consultationType]}
                              onChange={onProductOfferedChange(product)}
                              checked={product.checked}
                              withoutForm
                            />
                            {product.consultationType === ConsultationTypes.FreeConsultation ? (
                              <CheckboxLabel onClick={onProductOfferedChange(product)}>
                                {product.productName} (20-min)
                              </CheckboxLabel>
                            ) : (
                              <CheckboxLabel onClick={onProductOfferedChange(product)}>
                                {product.productName} (1-Hour)
                              </CheckboxLabel>
                            )}
                          </CheckboxWrapper>
                        </CheckboxWrapperAndPriceInputWrapper>
                      ))}
                  </CheckboxesWrapper>
                )}
              </Wrapper>
            </StepWrapper>
          )}
          <StepWrapper>
            <StepHeading>Specializations</StepHeading>
            <SubHeading size="medium">Select up to 10</SubHeading>
            {isProviderProfileInfoLoading ? (
              <Loader />
            ) : (
              <CriteriaList as="ul">
                {!!specializations.length &&
                  specializations.map((specialization: ProviderCriteria) => (
                    <CriteriaItem
                      size="small"
                      as="li"
                      key={specialization.id}
                      isSelected={!!providerProfileInfo.specializations.find(({ id }: any) => id === specialization.id)}
                      onClick={onSpecializationItemClick(specialization)}
                    >
                      {specialization.value}
                    </CriteriaItem>
                  ))}
              </CriteriaList>
            )}
          </StepWrapper>
          <StepWrapper>
            <StepHeading>Credentials</StepHeading>
            <Select
              name="credentials"
              options={credentialsOptions}
              customStyles={SelectStyles}
              placeholder={<div>credential</div>}
              dropdownIndicatorColor={colors.bismark}
              isMulti
            />
          </StepWrapper>
          <StepWrapper>
            <StepHeading>Bio</StepHeading>
            <SubHeading size="medium">2000 characters or less</SubHeading>
            <StyledTextarea name="biography" rows="10" maxLength="2000" />
          </StepWrapper>
          <StepWrapper>
            <StepHeading>Web and Social</StepHeading>
            <InputsWrapper>
              <StyledInput name="website" type="text" placeholder="website" size="small" />
              <StyledInput
                name="instagramHandle"
                type="text"
                placeholder="https://www.instagram.com/account"
                size="small"
              />
              <StyledInput
                name="facebookHandle"
                type="text"
                placeholder="https://www.facebook.com/account"
                size="small"
              />
              <StyledInput
                name="linkedInHandle"
                type="text"
                placeholder="https://www.linkedin.com/in/account"
                size="small"
              />
            </InputsWrapper>
          </StepWrapper>
          <StepWrapper>
            <StepHeading>Address</StepHeading>
            <AddressInput name="address" type="text" placeholder="Address" size="small" />
            <InputsWrapper>
              <StyledInput name="city" type="text" placeholder="City" size="small" />
              <Select
                name="state"
                options={STATES_OPTIONS}
                customStyles={SelectStyles}
                placeholder={<div>State</div>}
                dropdownIndicatorColor={colors.bismark}
              />
              <StyledInput name="zipcode" type="text" placeholder="Zip" size="small" />
            </InputsWrapper>
          </StepWrapper>
        </StepsWrapper>
        {submitFailed && hasValidationErrors && (
          <Error size="small">
            {!!Object.keys(errors).length &&
              `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
          </Error>
        )}
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <SubmitButton type="submit" disabled={submitting} isLoading={submitting}>
          save
        </SubmitButton>
        {submitSucceeded && <SuccessTitle>Saved</SuccessTitle>}
      </>
    )}
  </Form>
);

export default EditProfileForm;
