// library
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

// custom components
import Form from 'components/Form';
import { Title } from 'shared/typography';
import Input from 'components/Form/Input';
import PriceInput from 'components/Form/PriceInput';
import Select from 'components/Form/Select/Select';
import Textarea from 'components/Form/Textarea';
import Checkbox from 'components/Form/Checkbox';
import AvailabilityInput from './AvailabilityInput';
import UnavailabilityInput from './UnavailabilityInput';

// styled components
import { PrimaryButton } from 'shared/styledComponents';
import { Text, H4 } from 'shared/typography';

// styles
import devices from 'theme/devices';
import fonts from 'theme/fonts';
import colors from 'theme/colors';
import { SelectStyles, ErrorLabel } from './sharedStyles';
import 'react-datepicker/dist/react-datepicker.css';

// constants
import { WEEK_DAYS, LOCATIONS } from './constants';

//hooks
import useConvertedProviderCriteria from 'hooks/useConvertedProviderCriteria';

// validation
import { validateForm, hasFirstPageErrors } from './validation';

// services
import { convertProductValuesToDataModel, convertAvailabilitiesToDataModel } from './services';

const Heading = styled(Title)`
  margin-bottom: 25px;
`;

const SubHeading = styled(H4)`
  font-size: 16px;
  margin-bottom: 20px;
`;

const StepContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
`;

const StyledInput = styled(Input)`
  font-family: ${fonts.wigrumLight};
  border-radius: 4px;
  border: 1px solid ${colors.alto};
  background-color: rgba(255, 255, 255, 0.1);
  color: ${({ theme: { colors } }): string => colors.primary};
  padding: 13px 15px;
  outline: none;
  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightGray};
  }
`;

const StyledTextarea = styled(Textarea)(
  ({ theme: { colors, fonts } }) => `
      width: 100%;
      padding: 15px;
      border-radius: 4px;
      border: 1px solid ${colors.alto};
      background-color: ${colors.white};
      color: ${colors.primary};
      font-family: ${fonts.wigrumLight};
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
      outline: none;
      resize: none;
  
      @media ${devices.mobile} {
        margin-bottom: 20px;
      }
    `,
);

const FormControl = styled.div`
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;
  @media ${devices.mobile} {
    width: 100%;
  }
`;

const FormLabel = styled.label`
  font-size: 12px;
  margin-bottom: 5px;
  font-family: ${fonts.wigrumLight};
`;

const SelectPlaceholder = styled.div`
  font-family: ${fonts.wigrumLight};
`;

const PriceFormControl = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  position: relative;
  @media ${devices.mobile} {
    width: 100%;
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10;
  margin-right: 10px;
`;

const StyledPriceInput = styled(PriceInput)`
  font-family: ${fonts.wigrumLight};
  border-radius: 4px;
  border: 1px solid ${colors.alto};
  background-color: rgba(255, 255, 255, 0.1);
  color: ${colors.primary};
  padding: 13px 15px;
  outline: none;
  ::placeholder {
    color: ${colors.lightGray};
  }
  :disabled {
    color: ${colors.lightGray};
  }
  :disabled::placeholder {
    color: ${colors.alto};
  }
`;

const FreeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`;

const CheckboxLabel = styled(Text)`
  color: ${({ theme: { colors } }): string => colors.bismark};
  margin-left: 14px;
  font-size: 14px;
`;

const DurationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  @media ${devices.mobile} {
    justify-content: space-between;
  }
`;

const DurationChip = styled.option<{ isActive: boolean }>`
  border-radius: 50px;
  border: none;
  padding: 10px 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: ${fonts.wigrumLight};
  outline: none;
  color: ${(props): string => (props.isActive ? `${colors.pampas}` : `${colors.primary}`)};
  background-color: ${(props): string => (props.isActive ? `${colors.primary}` : `${colors.pampas}`)};
`;

const CTAButton = styled(PrimaryButton)`
  border: 2px solid ${colors.primary};
  margin-right: 10px;
`;

const CancelButton = styled(PrimaryButton)`
  background-color: transparent;
  color: ${colors.primary};
  display: inline-block;
  border: 2px solid ${colors.primary};
`;

interface AddProductFormProps {
  handleAddProductButton: (value: boolean) => void;
  setIsAddingProduct: (value: boolean) => void;
  setIsLoading: (value: boolean) => void;
  postProduct: (convertedValues: any, accessToken: string) => Promise<any>;
  putProduct: (id: string, convertedValues: any, accessToken: string) => Promise<any>;
  accessToken: string;
  productToEdit: any;
}

const emptyInitialValues = {
  id: null,
  availabilities: {
    monday: [{ start: null, end: null }],
    tuesday: [{ start: null, end: null }],
    wednesday: [{ start: null, end: null }],
    thursday: [{ start: null, end: null }],
    friday: [{ start: null, end: null }],
    saturday: [{ start: null, end: null }],
    sunday: [{ start: null, end: null }],
  },
  price: null,
  description: '',
  categoryId: '',
  duration: '',
  name: '',
  location: '',
  unavailableDates: [],
};

const AddProductForm = ({
  handleAddProductButton,
  setIsAddingProduct,
  setIsLoading,
  postProduct,
  accessToken,
  productToEdit,
  putProduct,
}: AddProductFormProps): JSX.Element => {
  const [step, setStep] = useState('STEP_1');
  const { serviceOptions } = useConvertedProviderCriteria(true);
  const [initialValues, setInitialValues] = useState(emptyInitialValues);
  const [isErrorShown, setIsErrorShown] = useState(false);

  const handleAddProductSubmit = useCallback(
    async (values: any) => {
      setIsLoading(true);

      try {
        const convertedValues = {
          ...convertProductValuesToDataModel(values),
          availabilities: convertAvailabilitiesToDataModel(values.availabilities),
        };

        if (values.id) {
          await putProduct(values.id, convertedValues, accessToken);
        } else {
          await postProduct(convertedValues, accessToken);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // set not loading and show myproducts
        window.scroll(0, 200);
        setIsAddingProduct(false);
        setIsLoading(false);
      }
    },
    [accessToken],
  );

  useEffect(() => {
    if (productToEdit) {
      setInitialValues(productToEdit);
    }
  }, [productToEdit]);

  return (
    <>
      <Heading>{productToEdit ? 'Edit Product' : 'Add Product'}</Heading>
      <Form
        validate={validateForm}
        initialValues={initialValues}
        onSubmit={handleAddProductSubmit}
        mutators={{
          removeAvailability: (args: any, state: any, utils: any): void => {
            const oldState = state.formState.values.availabilities;
            const newAvailabilities = { ...oldState };
            delete newAvailabilities[args[0]][args[1]];
            utils.changeValue(state, `availabilities`, () => newAvailabilities);
          },
          addAvailability: (args: any, state: any, utils: any): void => {
            const oldState = state.formState.values.availabilities;
            const newAvailabilities = { ...oldState };
            newAvailabilities[args[0]].push({ start: null, end: null });
            utils.changeValue(state, `availabilities`, () => newAvailabilities);
          },
          addUnavailableDate: (args: any, state: any, utils: any): void => {
            const newUnavailableDates = [...state.formState.values.unavailableDates];
            const dateString = args[0].toISOString();
            newUnavailableDates.push(dateString);
            utils.changeValue(state, `unavailableDates`, () => newUnavailableDates);
          },
          removeUnavailableDate: (args: any, state: any, utils: any): void => {
            const newUnavailableDates = [...state.formState.values.unavailableDates];
            newUnavailableDates.splice(args[0], 1);
            utils.changeValue(state, `unavailableDates`, () => newUnavailableDates);
          },
        }}
      >
        {({ form, values, errors, hasValidationErrors }) => {
          const { duration, location, categoryId } = values;
          const { reset, change } = form;

          return (
            <>
              {step === 'STEP_1' && (
                <StepContainer>
                  <SubHeading>Description</SubHeading>
                  <div>
                    <FormControl>
                      <FormLabel>Product name</FormLabel>
                      <StyledInput name="name" placeholder="Enter the product's name" />
                      <ErrorLabel>{isErrorShown && errors.name}</ErrorLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Product location</FormLabel>
                      <Select
                        name="location"
                        options={LOCATIONS}
                        value={LOCATIONS.find((loc: any): any => loc.value === location)}
                        placeholder={<SelectPlaceholder>Select a location</SelectPlaceholder>}
                        customStyles={SelectStyles}
                        onSelectChange={(location: any) => change('location', location.value)}
                      />
                      <ErrorLabel>{isErrorShown && errors.location}</ErrorLabel>
                    </FormControl>
                    <PriceFormControl>
                      <PriceWrapper>
                        <FormLabel>Price</FormLabel>
                        <StyledPriceInput value={values.price} placeholder="Enter a price" name="price" />
                        <ErrorLabel>{isErrorShown && errors.price}</ErrorLabel>
                      </PriceWrapper>
                      <FreeToggleWrapper>
                        <Checkbox
                          withoutForm
                          name="isFree"
                          value={values.price === 0}
                          checked={values.price === 0}
                          onChange={({ target: { value } }: any) => {
                            const isFree = value === 'true' ? false : true;
                            if (isFree) {
                              change('price', 0);
                            }
                          }}
                        />
                        <CheckboxLabel>Free</CheckboxLabel>
                      </FreeToggleWrapper>
                    </PriceFormControl>
                    <FormControl>
                      <FormLabel>Category</FormLabel>
                      <Select
                        name="categoryId"
                        options={serviceOptions}
                        value={serviceOptions.find((cat: any): any => cat.value === categoryId)}
                        placeholder={'Select a category'}
                        customStyles={SelectStyles}
                        onSelectChange={(category: any) => change('categoryId', category.value)}
                      />
                      <ErrorLabel>{isErrorShown && errors.category}</ErrorLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Description</FormLabel>
                      <StyledTextarea
                        placeholder="Enter a brief description of the product"
                        name="description"
                        rows="5"
                        maxLength="2000"
                      />
                    </FormControl>
                  </div>
                  <CTAButton
                    onClick={(): void => {
                      if (!hasFirstPageErrors(errors)) {
                        setStep('STEP_2');
                        setIsErrorShown(false);
                        window.scroll(0, 200);
                      } else {
                        setIsErrorShown(true);
                      }
                    }}
                  >
                    Next
                  </CTAButton>
                  <CancelButton
                    onClick={(): void => {
                      reset();
                      handleAddProductButton(false);
                    }}
                  >
                    Cancel
                  </CancelButton>
                </StepContainer>
              )}
              {step === 'STEP_2' && (
                <>
                  <StepContainer>
                    <SubHeading>Duration</SubHeading>
                    <DurationWrapper>
                      {[15, 30, 45, 60].map((durationOption) => (
                        <DurationChip
                          key={durationOption}
                          isActive={duration === durationOption}
                          onClick={(): void => change('duration', durationOption)}
                        >
                          {durationOption} min
                        </DurationChip>
                      ))}
                      <ErrorLabel>{isErrorShown && errors.duration}</ErrorLabel>
                    </DurationWrapper>
                  </StepContainer>
                  <StepContainer>
                    <SubHeading>Availabilities</SubHeading>

                    {WEEK_DAYS.map((dayName, idx) => (
                      <AvailabilityInput
                        key={`${dayName}_${idx}`}
                        name={dayName}
                        errors={errors}
                        isErrorShown={isErrorShown}
                        availabilities={values.availabilities[dayName]}
                        onAddAvailability={form.mutators.addAvailability}
                        onRemoveAvailability={form.mutators.removeAvailability}
                        onNotAvailable={change}
                        onSelectChange={change}
                      />
                    ))}
                    <ErrorLabel>{isErrorShown && errors.oneValidDay}</ErrorLabel>
                  </StepContainer>
                  <StepContainer>
                    <SubHeading>Unavailable Dates</SubHeading>
                    <UnavailabilityInput
                      unavailableDates={values.unavailableDates}
                      addUnavailableDate={form.mutators.addUnavailableDate}
                      removeUnavailableDate={form.mutators.removeUnavailableDate}
                    />
                  </StepContainer>
                  <CTAButton
                    type="submit"
                    onClick={() => {
                      if (hasValidationErrors) {
                        setIsErrorShown(true);
                      }
                    }}
                  >
                    {values.id ? 'Update' : 'Submit'}
                  </CTAButton>
                  <CancelButton
                    onClick={(): void => {
                      reset();
                      handleAddProductButton(false);
                    }}
                  >
                    Cancel
                  </CancelButton>
                </>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};

export default AddProductForm;
