// libraries
import { css, FlattenSimpleInterpolation } from 'styled-components';
import styled from 'styled-components';

// styles
import fonts from 'theme/fonts';
import colors from 'theme/colors';

// selectors styles
const SelectTextStyles = css`
  font-family: ${fonts.wigrumLight};
  font-size: 14px;
  color: ${colors.primary};
`;

export const SelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
  `,
  control: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    background-color: rgba(255, 255, 255, 0.1);
    min-height: 48px;
    padding: 0 14px;
    box-shadow: none;
    cursor: pointer;
  `,
  input: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  valueContainer: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      padding: 0;
    `,
  placeholder: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
    color: ${colors.lightGray};
    font-weight: light;
  `,
  singleValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  multiValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      background-color: unset;
    `,
  multiValueLabel: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  multiValueRemove: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    :hover {
      background-color: unset;
      color: currentColor;
    }
  `,
  menuList: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      color: ${colors.bismark};
    `,
  indicatorSeparator: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      display: none;
    `,
};

export const DurationSelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
  `,
};

export const ErrorLabel = styled.div`
  bottom: -15px;
  position: absolute;
  font-size: 12px;
  color: ${colors.red};
  font-family: ${fonts.wigrumLight};
`;
