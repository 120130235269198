// libraries
import React from 'react';
import NumberFormat from 'react-number-format';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

const CurrencyInput = (props: any) => {
  return (
    <NumberFormat
      {...props}
      value={props.input.value}
      prefix="$"
      thousandSeparator
      fixedDecimalScale={true}
      allowNegative={false}
      autoComplete="off"
      onBlur={props.input.onBlur}
      onFocus={props.input.onFocus}
      onValueChange={({ formattedValue, value }) => {
        const newValue = value ? parseInt(value) : null;
        props.input.onChange(newValue);
      }}
    />
  );
};

const Input = (props: FieldProps<string, FieldRenderProps<string, HTMLElement>, HTMLInputElement>): JSX.Element => {
  return <Field {...props} component={CurrencyInput} />;
};

export default Input;
