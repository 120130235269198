/* eslint-disable react-hooks/exhaustive-deps */

// libraries
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// custom components
import ProductsOfferedForm from './ProductsOfferedForm';

// hooks
import useModal from 'hooks/useModal';
import useProviderPaymentInfo from 'hooks/useProviderPaymentInfo';

// selectors
import { selectUser } from 'modules/User/selectors';

// services
import { getProductsOffered, postProductsOffered } from './services';

// types
import { OfferedProduct } from 'pages/ProviderProfile/types';
import { ConsultationTypes } from 'types';

// constants
import { UserRoles } from 'constants/user';

const ProductsOfferedContainer = (): JSX.Element => {
  const history = useHistory();

  const { handleModalOpen } = useModal();

  const [isProductsOfferedLoading, setIsProductsOfferedLoading] = useState(false);

  const [productsOffered, setProductsOffered] = useState([]);

  const {
    userId,
    role,
    token: { accessToken },
  } = useSelector(selectUser);

  const { providerPaymentInfo } = useProviderPaymentInfo(accessToken);

  useEffect(() => {
    (async (): Promise<any> => {
      setIsProductsOfferedLoading(true);
      try {
        const providersProfileInfo = await getProductsOffered(accessToken);

        setProductsOffered(providersProfileInfo.data);
      } catch (err) {
        console.log(err);
      }
      setIsProductsOfferedLoading(false);
    })();
  }, [accessToken, userId]);

  const initialValues = useMemo(
    () =>
      !!productsOffered.length &&
      productsOffered.reduce((initialValues, product: any) => {
        if (product.checked && product.pricing) {
          initialValues[`${ConsultationTypes[product.consultationType]}Price`] = product.pricing.price;
        }

        return initialValues;
      }, {} as any),
    [productsOffered.length],
  );

  const handleProductOfferedChange = useCallback(
    (selectedProduct: OfferedProduct) => () => {
      setProductsOffered((state: any): any =>
        state.map((product: any) =>
          product.id === selectedProduct.id ? { ...product, checked: !product.checked } : { ...product },
        ),
      );
    },
    [],
  );

  const handleNotificationModalButtonClick = useCallback(() => {
    history.push('/provider/dashboard/products/payment-info');
  }, [history]);

  const onSubmit = useCallback(
    async (values: any) => {
      const checkedProductsOffered = productsOffered.filter((propduct: any) => propduct.checked);

      const paidProductsOffered = checkedProductsOffered.find(
        (propduct: any) => propduct.consultationType !== ConsultationTypes.FreeConsultation,
      );

      await postProductsOffered(checkedProductsOffered, values, accessToken);

      if (paidProductsOffered && !providerPaymentInfo.payoutsEnabled && role === UserRoles.PROVIDER_PRO) {
        handleModalOpen('notificationModal', {
          description:
            'Saved. Your availability will remain hidden until you connect your bank account to receive payment.',
          buttonLabel: 'Ok',
          onButtonClick: handleNotificationModalButtonClick,
        })();
      }
    },
    [
      productsOffered,
      handleModalOpen,
      handleNotificationModalButtonClick,
      providerPaymentInfo.payoutsEnabled,
      accessToken,
    ],
  );

  return (
    <ProductsOfferedForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      isProductsOfferedLoading={isProductsOfferedLoading}
      productsOffered={productsOffered}
      onProductOfferedChange={handleProductOfferedChange}
    />
  );
};

export default ProductsOfferedContainer;
