// api
import apiRequest from 'api/restapi';
// libraries
import { AxiosResponse } from 'axios';

const getProducts = (providerId: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`/products/provider/${providerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const postProduct = (product: any, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(`/products`, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const putProduct = (id: string, product: any, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.put(`/products/${id}`, product, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const deleteProduct = (id: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.delete(`/products/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default {
  getProducts,
  postProduct,
  putProduct,
  deleteProduct,
};
