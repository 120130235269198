// libraries
import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

// pages
import Appointments from 'pages/ProviderDashboard/SubPages/Appointments/Availability';
import EditProfile from 'pages/ProviderDashboard/SubPages/EditProfile';
import Analytics from 'pages/ProviderDashboard/SubPages/Analytics';
import PrivateProviderNetwork from 'pages/ProviderDashboard/SubPages/PrivateProviderNetwork';
import Settings from 'pages/ProviderDashboard/SubPages/Settings';
import ProductsOffered from 'pages/ProviderDashboard/SubPages/Appointments/ProductsOffered';
import UpcomingAppointments from 'pages/ProviderDashboard/SubPages/Appointments/UpcomingAppointments';
import PaymentInfo from 'pages/ProviderDashboard/SubPages/Appointments/PaymentInfo';
import AvailabilitySuccess from 'pages/ProviderDashboard/SubPages/Appointments/AvailabilitySuccess';
import ProviderOnboarding from 'pages/ProviderOnboarding';

import MyProductsPage from 'pages/ProviderDashboard/SubPages/Products/MyProductsPage';

// constants
import { UserRoles } from 'constants/user';

const PROVIDER_URLS = [
  '/provider/dashboard/edit-profile',
  '/provider/dashboard/settings',
  '/provider/dashboard/private-provider-network',
  '/provider/dashboard/analytics',
  '/provider/dashboard/appointments/products-offered',
  '/provider/dashboard/appointments/availability',
  '/provider/dashboard/appointments/availability/complete',
  '/provider/dashboard/appointments/upcoming-appointments',
  '/provider/dashboard/products/payment-info',
];

interface ProviderBasicOrProRoutesProps {
  userRole: string;
  isLoggedIn: boolean;
}

const ProviderBasicOrProRoutes = ({ userRole, isLoggedIn }: ProviderBasicOrProRoutesProps): JSX.Element => {
  const location = useLocation();

  return (
    <>
      {!isLoggedIn && PROVIDER_URLS.includes(location.pathname) && (
        <Redirect
          to={{
            pathname: '/login',
            state: { locationRedirectPath: location.pathname, locationRedirectSearch: location.search },
          }}
        />
      )}

      {(userRole === UserRoles.PROVIDER_APPLICANT || !isLoggedIn) && (
        <Route path="/create-provider-account" component={ProviderOnboarding} />
      )}

      {isLoggedIn && userRole === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
        <>
          <Route path="/provider/dashboard/settings" component={Settings} />
        </>
      )}
      {isLoggedIn && userRole === UserRoles.PROVIDER_BASIC && (
        <>
          <Route path="/provider/dashboard/edit-profile" component={EditProfile} />
          <Route path="/provider/dashboard/settings" component={Settings} />
          <Route path="/provider/dashboard/private-provider-network" component={PrivateProviderNetwork} />
        </>
      )}
      {isLoggedIn && userRole === UserRoles.PROVIDER_PRO && (
        <>
          <Route path="/provider/dashboard/edit-profile" component={EditProfile} />
          <Route path="/provider/dashboard/analytics" component={Analytics} />
          <Route path="/provider/dashboard/private-provider-network" component={PrivateProviderNetwork} />
          <Route path="/provider/dashboard/products/my-products" component={MyProductsPage} />
          <Route path="/provider/dashboard/appointments/products-offered" component={ProductsOffered} />
          <Route exact path="/provider/dashboard/appointments/availability" component={Appointments} />
          <Route exact path="/provider/dashboard/appointments/availability/complete" component={AvailabilitySuccess} />
          <Route path="/provider/dashboard/appointments/upcoming-appointments" component={UpcomingAppointments} />
          <Route path="/provider/dashboard/products/payment-info" component={PaymentInfo} />
          <Route path="/provider/dashboard/settings" component={Settings} />
        </>
      )}
    </>
  );
};

export default ProviderBasicOrProRoutes;
