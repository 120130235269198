// libraries
import React, { useState } from 'react';
import styled from 'styled-components';
// custom components
import Icon from 'components/Icon';
import devices from 'theme/devices';

interface ContainerProps {
  isHidden: boolean;
}

const Container = styled.div<ContainerProps>`
  position: fixed;
  width: 100%;
  z-index: 9999;
  bottom: 10px;
  display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  justify-content: center;
`;

const Wrapper = styled.div`
  max-width: 70%;
  background-color: ${({ theme: { colors } }): string => colors.bismark};
  display: flex;
  padding: 10px 15px;
  @media ${devices.mobile} {
    max-width: 100%;
  }
`;

const Text = styled.p`
  color: white;
  font-family: 'Wigrum-Light';
  line-height: 150%;
  font-size: 14px;
`;

const ButtonWrapper = styled.span`
  cursor: pointer;
`;

const UpdatedTermsNotification = (): JSX.Element => {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <Container isHidden={isClosed}>
      <Wrapper>
        <Text>
          We have updated our Terms of Use and Privacy Notice. You should review the updated links so that you
          understand Robyn&apos;s policies and procedures regarding the collection, use, disclosure, and security of
          your personal information.
        </Text>

        <ButtonWrapper>
          <Icon type="close" color="white" width="16px" height="16px" onClick={() => setIsClosed(true)} />
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default UpdatedTermsNotification;
