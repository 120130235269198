// libraries
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

// custom components
import ProviderDashboardLayout from '../../ProviderDashboardLayout';
import ProductsLayout from './ProductsLayout';
import MyProducts from 'modules/Dashboard/ProviderDashboard/Products/MyProducts';
import AddProductForm from 'modules/Dashboard/ProviderDashboard/Products/AddProductForm';
import Loader from 'components/Loader';

// styled components
import { CentredFlex } from 'shared/styledComponents';

// api
import api from './api';

// selectors
import { selectUser } from 'modules/User/selectors';

// hooks
import useModal from 'hooks/useModal';

const LoaderContainer = styled(CentredFlex)`
  min-height: 300px;
`;

const MyProductsPage = (): JSX.Element => {
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productToEdit, setProductToEdit] = useState(null);
  const { handleModalOpen } = useModal();

  const {
    userId: providerId,
    token: { accessToken },
  } = useSelector(selectUser);

  const handleAddProductButton = (isAddingProduct: boolean): void => {
    window.scroll(0, 200);
    setIsAddingProduct(isAddingProduct);
    setProductToEdit(null);
  };

  const handleEditProductClick = (product: any): void => {
    setIsAddingProduct(true);
    setProductToEdit(product);
  };

  const handleGetProducts = async (providerId: string, accessToken: string): Promise<any> => {
    try {
      const {
        data: { products },
      } = await api.getProducts(providerId, accessToken);
      setProducts(products);
    } catch (err) {
      console.log(err.message);
    }
  };

  const onDeleteProduct = async (id: string): Promise<any> => {
    setIsLoading(true);
    try {
      await api.deleteProduct(id, accessToken);
    } catch (err) {
      console.log(err.message);
    } finally {
      await handleGetProducts(providerId, accessToken);
      setIsLoading(false);
    }
  };

  const handleDeleteProductClick = (id: string): void => {
    handleModalOpen('deleteProductModal', {
      onConfirmButtonClick: () => onDeleteProduct(id),
      title: 'Delete Product',
      description: "Are you sure you'd like to delete this product?",
      cancelButtonLabel: 'Cancel',
      confirmButtonLabel: 'Yes',
    })();
  };

  useEffect(() => {
    (async (): Promise<any> => {
      setIsLoading(true);
      try {
        await handleGetProducts(providerId, accessToken);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isAddingProduct]);

  return (
    <ProviderDashboardLayout>
      <ProductsLayout>
        {isLoading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {!isAddingProduct && !isLoading && (
          <MyProducts
            handleEditProductClick={handleEditProductClick}
            handleDeleteProductClick={handleDeleteProductClick}
            products={products}
            handleAddProductButton={handleAddProductButton}
          />
        )}
        {isAddingProduct && !isLoading && (
          <AddProductForm
            productToEdit={productToEdit}
            putProduct={api.putProduct}
            postProduct={api.postProduct}
            accessToken={accessToken}
            setIsAddingProduct={setIsAddingProduct}
            setIsLoading={setIsLoading}
            handleAddProductButton={handleAddProductButton}
          />
        )}
      </ProductsLayout>
    </ProviderDashboardLayout>
  );
};

export default MyProductsPage;
