import React from 'react';
import styled from 'styled-components';
// styles
import devices from 'theme/devices';
import fonts from 'theme/fonts';
import colors from 'theme/colors';

// custom components
import PriceDisplay from 'components/PriceDisplay';

// custom components
import Icon from 'components/Icon';

// services
import { convertAvailabilitiesToSelectModel } from './services';

const ProductContainer = styled.div`
  width: 250px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid ${colors.lightGray};
  color: ${colors.lightBrown};
  font-family: ${fonts.wigrumRegular};

  @media ${devices.mobile} {
    margin-right: 0px;
    width: 100%;
  }
`;

const ProductHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Icons = styled.div`
  display: flex;
`;

const ProductIcon = styled(Icon)`
  cursor: pointer;
  margin: 4px;
`;

const ProductTitleContainer = styled.div`
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const ProductTitle = styled.h3`
  font-size: 16px;
  margin-bottom: 6px;
`;

const ProductLocation = styled.span`
  font-size: 12px;
`;

const ProductMeta = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

interface ProductProps {
  product: any;
  onEditProductClick: (value: any) => void;
  onDeleteProductClick: (value: any) => void;
}

const getPrice = (price: number): JSX.Element => {
  if (price === 0) return <span>Free</span>;

  return <PriceDisplay name="price" value={price} />;
};

const Product = ({ product, onEditProductClick, onDeleteProductClick }: ProductProps): JSX.Element => {
  return (
    <ProductContainer>
      <ProductHeading>
        <ProductTitleContainer>
          <ProductTitle>{product.name}</ProductTitle>
          <ProductLocation>{product.location}</ProductLocation>
        </ProductTitleContainer>

        <Icons>
          <ProductIcon
            type="edit"
            width="17.5px"
            height="17.5px"
            onClick={() => {
              // Groom product to be editable
              product.availabilities = convertAvailabilitiesToSelectModel(product.availabilities);
              product.duration = parseInt(product.duration) || null;
              product.price === 0 ? (product.isFree = true) : (product.isFree = false);
              onEditProductClick(product);
            }}
          />
          <ProductIcon type="delete" width="17.5px" height="17.5px" onClick={() => onDeleteProductClick(product.id)} />
        </Icons>
      </ProductHeading>
      <ProductMeta>
        {product.duration} min | {getPrice(product.price)}
      </ProductMeta>
    </ProductContainer>
  );
};

export default Product;
