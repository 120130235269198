export const convertAvailabilitiesToSelectModel = (availabilities: any) => {
  const convertedAvailablities: any = {};

  for (const day in availabilities) {
    const validTimes: any = [];
    availabilities[day].forEach((time: any) => {
      if (typeof time.start === 'string' && typeof time.end === 'string') {
        // Convert start time to local date
        const localStartDate = new Date(time.start);
        const startHour = localStartDate.getHours();
        // Convert end time to local date
        const localEndDate = new Date(time.end);
        const endHour = localEndDate.getHours();

        const convertedTime = {
          start: startHour,
          end: endHour,
        };
        validTimes.push(convertedTime);
      }
    });
    convertedAvailablities[day] = validTimes;
  }

  return convertedAvailablities;
};
