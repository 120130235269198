const checkValidInterval = (start: number, end: number): boolean => {
  return start < end;
};

const hasAtLeastOneValidDay = (availabilities: any): boolean => {
  let hasAtLeastOneDay = false;
  for (const day in availabilities) {
    const hasDay = availabilities[day].some((time: any) => checkValidInterval(time.start, time.end));
    if (hasDay) hasAtLeastOneDay = true;
  }

  return hasAtLeastOneDay;
};

export const validateForm = (values: any) => {
  const errors: any = {};
  if (!values.name?.trim()) {
    errors.name = 'Name is required';
  }
  if (values.name?.length >= 50) {
    errors.name = 'Name should be no more than 50 characters';
  }
  if (!values.location) {
    errors.location = 'Location is required';
  }
  // Check if undefined or null
  if (values.price == null) {
    errors.price = 'Price is required';
  }
  if (!values.categoryId) {
    errors.category = 'Category is required';
  }
  if (!values.duration) {
    errors.duration = 'A duration is required';
  }

  if (!hasAtLeastOneValidDay(values.availabilities)) {
    errors.oneValidDay = 'Must have at least one available day';
  }

  return errors;
};

export const hasFirstPageErrors = (errors: any): boolean => {
  const firstPage = [errors.name, errors.location, errors.price, errors.category];
  return firstPage.some((error) => !!error === true);
};
