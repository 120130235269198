export interface CreateAccountData {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  termsAccepted: boolean;
}

export const validationMessages = {
  termsAccepted: 'The Terms are ',
  name: 'Name is ',
  firstName: 'First Name is ',
  lastName: 'Last Name is ',
  email: 'Email is ',
  oldPassword: 'Old Password is ',
  password: 'Password is ',
  city: 'City is ',
  phone: 'Phone Number is ',
  confirmPassword: 'Confirm Password is ',
  certificationAccepted: 'Non-Covered Entity Certification is ',
  stage: 'Stage is ',
  body: 'Body is ',
  biography: 'Biography is',
  timeZone: 'Time Zone is',
  fileType: 'Wrong file format.',
  fileSize: 'The file size is',
  facebookHandle: 'Facebook link is',
  instagramHandle: 'Instagram link is',
  linkedInHandle: 'linkedIn link is',
  website: 'Website link is',
};
