// libraries
import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

// custom components
import Select from 'components/Form/Select/Select';
import Icon from 'components/Icon';
import Checkbox from 'components/Form/Checkbox';

// styled components
import { Title, Text } from 'shared/typography';
import { Flex, ColumnedFlex, SecondaryButton } from 'shared/styledComponents';
import { ErrorLabel } from '../sharedStyles';

// styles
import colors from 'theme/colors';
import devices from 'theme/devices';
import fonts from 'theme/fonts';

// constants
import { TIME_OPTIONS } from '../constants';

const Wrapper = styled(ColumnedFlex)`
  align-items: flex-start;
  margin-bottom: 40px;

  :last-child {
    margin-bottom: 0;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
`;

const Heading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
`;

const SelectsWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 25px;
  position: relative;
`;

const AddOneMoreButton = styled(SecondaryButton)``;

const SelectHeading = styled(Heading)`
  margin: 0 40px;
  color: ${(props) => (props.isDisabled ? colors.alto : colors.primary)};
  @media ${devices.mobile} {
    margin: 0 10px;
  }
`;

const SelectTextStyles = css`
  font-family: ${fonts.wigrumLight};
  font-size: 14px;
  color: ${colors.lightBrown};
`;

const SelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    width: 176px;
    @media ${devices.mobile} {
      width: 122px;
    }
  `,
  control: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0 14px;
    box-shadow: none;
    cursor: pointer;

    @media ${devices.mobile} {
      padding: 0 10px;
    }
  `,
  input: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  valueContainer: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      padding: 0;
    `,
  placeholder: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  singleValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  menuList: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      color: ${colors.bismark};
    `,
  indicatorSeparator: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      display: none;
    `,
};

const StyledCloseIcon = styled(Icon)<any>`
  margin-left: 15px;
  cursor: pointer;
`;

const FreeToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: 10px;
`;

const CheckboxLabel = styled(Text)`
  color: ${({ theme: { colors } }): string => colors.bismark};
  margin-left: 10px;
  font-size: 14px;
`;

interface AvailabilityInputProps {
  name: string;
  availabilities: any;
  onRemoveAvailability: (name: string, idx: number) => void;
  onAddAvailability: (name: string) => void;
  onNotAvailable: (name: string, value: boolean) => void;
  errors: any;
  isErrorShown: boolean;
  onSelectChange: (name: string, value: number) => void;
}

const findOption = (value: number, options: any): any => {
  let option = null;
  if (value !== null) {
    option = options.find((option: any) => option.value === value);
  }

  return option;
};

const findAvailableOptions = (currentOption: number, type: string, options: any): any => {
  if (type === 'start' && currentOption !== null) {
    return options.filter((option: any) => option.value < currentOption);
  }
  if (type === 'end' && currentOption !== null) {
    return options.filter((option: any) => option.value > currentOption);
  }
  return options;
};

const AvailabilityInput = ({
  availabilities,
  name,
  onAddAvailability,
  onRemoveAvailability,
  onNotAvailable,
  errors,
  isErrorShown,
  onSelectChange,
}: AvailabilityInputProps): JSX.Element => {
  const isUnavailable = availabilities.length === 0;

  return (
    <Wrapper>
      <HeadingContainer>
        <Heading size="small">{name.charAt(0).toUpperCase() + name.slice(1)}</Heading>

        <FreeToggleWrapper>
          <Checkbox
            checked={isUnavailable}
            value={isUnavailable}
            withoutForm
            name="isFree"
            onChange={({ target: { value } }: any) => {
              const isUnavailable = value === 'true' ? false : true;
              let newValue: any = [];
              if (!isUnavailable) {
                newValue = [{ start: null, end: null }];
              }
              onNotAvailable(`availabilities[${name}]`, newValue);
            }}
          />
          <CheckboxLabel>Not Available</CheckboxLabel>
        </FreeToggleWrapper>
      </HeadingContainer>

      {availabilities.map((availability: any, idx: number) => {
        // Pass numeric value into helper to get the object expected by react-select
        const start = findOption(availability.start, TIME_OPTIONS);
        const end = findOption(availability.end, TIME_OPTIONS);

        // Find valid options based on selected option, if no option has been selected all options are valid
        const startOptions = findAvailableOptions(availability.end, 'start', TIME_OPTIONS);
        const endOptions = findAvailableOptions(availability.start, 'end', TIME_OPTIONS);

        return (
          <SelectsWrapper key={`${name}_${idx}`}>
            <Select
              value={start}
              options={startOptions}
              customStyles={SelectStyles}
              dropdownIndicatorColor={colors.bismark}
              dropdownIndicatorWidth="15px"
              dropdownIndicatorHeight="9px"
              onSelectChange={(option: any) => onSelectChange(`availabilities[${name}][${idx}].start`, option.value)}
            />
            <SelectHeading isDisabled={availability.isUnavailable}>to</SelectHeading>
            <Select
              value={end}
              options={endOptions}
              customStyles={SelectStyles}
              dropdownIndicatorWidth="15px"
              dropdownIndicatorHeight="9px"
              dropdownIndicatorColor={colors.bismark}
              onSelectChange={(option: any) => onSelectChange(`availabilities[${name}][${idx}].end`, option.value)}
            />
            {idx !== 0 && (
              <StyledCloseIcon
                type="close"
                width="10px"
                height="10px"
                onClick={() => onRemoveAvailability(name, idx)}
              />
            )}
            <ErrorLabel>
              {isErrorShown && errors[name]?.find((error: any): any => error.idx === idx)?.errorMsg}
            </ErrorLabel>
          </SelectsWrapper>
        );
      })}

      <AddOneMoreButton type="button" onClick={() => onAddAvailability(name)} size="small">
        + add another interval
      </AddOneMoreButton>
    </Wrapper>
  );
};

export default AvailabilityInput;
