// Lib
import React from 'react';
import styled from 'styled-components';

// SVG icons
import { ReactComponent as DownArrow } from 'assets/icons/downArrow.svg';
import { ReactComponent as GridIcon } from 'assets/icons/GridIcon.svg';
import { ReactComponent as ListIcon } from 'assets/icons/ListIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as MakerIcon } from 'assets/icons/marker.svg';
import { ReactComponent as BurgerMenu } from 'assets/icons/burgerMenu.svg';
import { ReactComponent as BurgerMenuWhite } from 'assets/icons/burgerMenuWhite.svg';
import { ReactComponent as OpenedBurgerMenu } from 'assets/icons/openedBurgerMenu.svg';
import { ReactComponent as RealStory } from 'assets/icons/RealStory.svg';
import { ReactComponent as Facebook } from 'assets/icons/facebook.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Website } from 'assets/icons/site.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg';
import { ReactComponent as Location } from 'assets/icons/location.svg';
import { ReactComponent as EditIcon } from 'assets/icons/EditIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIcon.svg';

const Icons: any = {
  GridIcon: <GridIcon />,
  ListIcon: <ListIcon />,
  downArrow: <DownArrow />,
  close: <CloseIcon />,
  calendar: <CalendarIcon />,
  marker: <MakerIcon />,
  burgerMenu: <BurgerMenu />,
  burgerMenuWhite: <BurgerMenuWhite />,
  openedBurgerMenu: <OpenedBurgerMenu />,
  realStory: <RealStory />,
  facebook: <Facebook />,
  mail: <Mail />,
  twitter: <Twitter />,
  website: <Website />,
  instagram: <Instagram />,
  location: <Location />,
  linkedin: <Linkedin />,
  edit: <EditIcon />,
  delete: <DeleteIcon />,
};

const IconWrapper = styled.span<any>`
  display: flex;

  & i {
    display: inherit;
  }

  & svg {
    width: ${(props): string => (props.width ? props.width : '25px')};
    height: ${(props): string => (props.height ? props.height : '25px')};
    fill: ${({ color, theme: { colors } }): string => (color ? color : colors.primary)};
  }
  & g {
    fill: ${({ color, theme: { colors } }): string => (color ? color : colors.primary)};
  }
`;

interface IconProps {
  type: string;
  onClick?: VoidFunction;
  color?: string;
  width?: string;
  height?: string;
}

const Icon: React.FC<IconProps> = ({ type, onClick, color, width, height, ...props }): JSX.Element => (
  <IconWrapper color={color} width={width} height={height} onClick={onClick} {...props}>
    <i>{Icons[type]}</i>
  </IconWrapper>
);

export default Icon;
