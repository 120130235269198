// libraries
import React, { ReactNode, Suspense } from 'react';
import styled from 'styled-components';
import { NavLink as ReactRouterLink } from 'react-router-dom';

// styled components
import { ColumnedFlex, Frame, Link, Flex, PrimaryButton } from 'shared/styledComponents';
import { H2, Title } from 'shared/typography';

// assets
import defaultProviderImage from 'assets/images/defaultProviderImage.png';

// constants
import { UserRoles } from 'constants/user';

// styles
import devices from 'theme/devices';

// types
import { LinkNames } from './types';

// custom components
import Header from 'components/Header';
import Icon from 'components/Icon';

const Footer = React.lazy(() => import('components/Footer'));

const Wrapper = styled.div``;

const InfoBlockWrapper = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  margin-bottom: 110px;

  @media ${devices.mobile} {
    margin-bottom: 40px;
  }
`;

const InfoBlock = styled(Frame)`
  display: flex;
  align-items: center;

  @media ${devices.mobile} {
    padding-bottom: 35px;
  }
`;

const ImageWrapper = styled.div<any>`
  width: 225px;
  height: 225px;
  margin-right: 65px;
  margin-bottom: -20px;
  background-image: ${({ src }): string => `url(${src})`};
  background-position: center;
  background-size: cover;
  background-position-y: 0;
  background-repeat: no-repeat;
  border-radius: 50%;
  cursor: pointer;
  display: block;

  @media ${devices.mobile} {
    display: none;
  }
`;

const Info = styled(ColumnedFlex)``;

const Name = styled(H2)<any>`
  text-decoration: none;
  cursor: pointer;

  @media ${devices.mobile} {
    margin-bottom: 15px;
    font-size: 45px;
    line-height: 49.5px;
  }
`;

const MembershipWrapper = styled(Flex)`
  align-items: center;
`;

const Membership = styled(Title)`
  margin-right: 10px;

  @media ${devices.mobile} {
    font-size: 22px;
    line-height: 28px;
  }
`;

const ContentWrapper = styled(Frame)`
  display: flex;
  position: relative;
  margin-bottom: 85px;

  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const Navigation = styled(ColumnedFlex)`
  width: 100%;
  max-width: 190px;
  margin-right: 100px;

  @media ${devices.mobile} {
    flex-direction: row;
    max-width: 310px;
    margin-right: 0;
    overflow-x: auto;
  }
`;

const NavigationWrapper = styled(Flex)`
  @media ${devices.mobile} {
    margin-bottom: 40px;
  }
`;

const NavigationLinkWrapper = styled(Flex)<any>`
  align-items: center;
  border-bottom: ${({ theme: { colors } }): string => `1px solid ${colors.alto}`};
  padding: 30px 0;
  white-space: nowrap;

  :first-child {
    padding-top: 0;
  }

  :last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  @media ${devices.mobile} {
    min-height: 45px;
    padding: 10px 0;
    padding-right: 25px;
    white-space: nowrap;
    border-bottom: 0;

    :first-child {
      padding-top: 10px;
    }

    :last-child {
      padding-right: 0;
      padding-bottom: 10px;
    }
  }
`;

const ActiveNavLinkStyles = {
  opacity: '80%',
};

const ProButton = styled(PrimaryButton)`
  border-radius: 4px;
  font-size: 8px;
  padding: 8px 10px;
  line-height: 1;
  margin-right: 10px;
`;

const DisabledTitle = styled(Title)`
  opacity: 0.6;
`;

const StyledArrowIcon = styled(Icon)`
  align-self: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const StyledLeftIcon = styled(StyledArrowIcon)`
  margin-right: 10px;
  transform: rotate(90deg);
`;

const StyledRightIcon = styled(StyledArrowIcon)`
  margin-left: 10px;
  transform: rotate(-90deg);
`;

const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const getCapitalizedName = (name: string) => {
  return name
    .toLowerCase()
    .split(' ')
    .map((s) => capitalize(s))
    .join(' ')
    .split('-')
    .map((s) => capitalize(s))
    .join('-');
};

interface ProviderDashboardLayoutProps {
  children: ReactNode;
  firstName: string;
  lastName: string;
  slug: string;
  role: string;
  isDesktop: boolean;
  handlLeftArrowClick: any;
  handleRightArrowClick: any;
  providerImage: string;
  onProButtonClick: VoidFunction;
}

const ProviderDashboardLayout = ({
  children,
  firstName,
  lastName,
  slug,
  role,
  onProButtonClick,
  handleRightArrowClick,
  handlLeftArrowClick,
  isDesktop,
  providerImage,
}: ProviderDashboardLayoutProps): JSX.Element => (
  <Wrapper>
    <Header />
    <InfoBlockWrapper>
      <InfoBlock>
        <ImageWrapper
          as={ReactRouterLink}
          to={`/maternal-specialist/${slug}`}
          data-bg={providerImage || defaultProviderImage}
          target="_blank"
          className="lazyload"
        />
        <Info>
          <Name as={ReactRouterLink} to={`/maternal-specialist/${slug}`} target="_blank">
            {firstName && lastName ? `${getCapitalizedName(firstName)} ${getCapitalizedName(lastName)}` : undefined}
          </Name>
          {role === UserRoles.PROVIDER_BASIC && (
            <MembershipWrapper>
              <Membership>Basic Membership</Membership>
              <ProButton onClick={onProButtonClick}>upgrade to pro</ProButton>
            </MembershipWrapper>
          )}
          {role === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
            <MembershipWrapper>
              <Membership>Select Subscription</Membership>
            </MembershipWrapper>
          )}
          {role === UserRoles.PROVIDER_PRO && (
            <MembershipWrapper>
              <Membership>Pro Membership</Membership>
            </MembershipWrapper>
          )}
        </Info>
      </InfoBlock>
    </InfoBlockWrapper>
    <ContentWrapper>
      <NavigationWrapper>
        {!isDesktop && <StyledLeftIcon type="downArrow" width="18px" height="13px" onClick={handlLeftArrowClick} />}
        <Navigation id="navigation-block">
          {(role === UserRoles.PROVIDER_PRO || role === UserRoles.PROVIDER_BASIC) && (
            <NavigationLinkWrapper>
              <Link
                as={ReactRouterLink}
                to="/provider/dashboard/edit-profile"
                activeStyle={ActiveNavLinkStyles}
                size="small"
                id={LinkNames.profile}
              >
                Profile
              </Link>
            </NavigationLinkWrapper>
          )}
          {role === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
            <NavigationLinkWrapper>
              {/* <ProButton onClick={onProButtonClick}>Pro</ProButton> */}
              <DisabledTitle size="small">Profile</DisabledTitle>
            </NavigationLinkWrapper>
          )}

          {role === UserRoles.PROVIDER_PRO && (
            <NavigationLinkWrapper>
              <Link
                as={ReactRouterLink}
                to="/provider/dashboard/appointments/products-offered"
                activeStyle={ActiveNavLinkStyles}
                size="small"
                id={LinkNames.scheduling}
              >
                Scheduling
              </Link>
            </NavigationLinkWrapper>
          )}

          {role === UserRoles.PROVIDER_PRO && (
            <NavigationLinkWrapper>
              <Link
                as={ReactRouterLink}
                to="/provider/dashboard/products/my-products"
                activeStyle={ActiveNavLinkStyles}
                size="small"
                id={LinkNames.scheduling}
              >
                Products
              </Link>
            </NavigationLinkWrapper>
          )}

          {role === UserRoles.PROVIDER_BASIC && (
            <NavigationLinkWrapper>
              <ProButton onClick={onProButtonClick}>Pro</ProButton>
              <DisabledTitle size="small">Scheduling</DisabledTitle>
            </NavigationLinkWrapper>
          )}

          {role === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
            <NavigationLinkWrapper>
              {/* <ProButton onClick={onProButtonClick}>Pro</ProButton> */}
              <DisabledTitle size="small">Scheduling</DisabledTitle>
            </NavigationLinkWrapper>
          )}

          {/* {role === UserRoles.PROVIDER_PRO && (
            <NavigationLinkWrapper>
              <Link
                as={ReactRouterLink}
                to="/provider/dashboard/analytics"
                activeStyle={ActiveNavLinkStyles}
                size="small"
                id={LinkNames.analytics}
              >
                Analytics
              </Link>
            </NavigationLinkWrapper>
          )} */}
          {/* {role === UserRoles.PROVIDER_BASIC && (
            <NavigationLinkWrapper>
              <ProButton onClick={onProButtonClick}>Pro</ProButton>
              <DisabledTitle size="small">Analytics</DisabledTitle>
            </NavigationLinkWrapper>
          )} */}
          {role === UserRoles.PROVIDER_PRO && (
            <NavigationLinkWrapper>
              <Link
                as={ReactRouterLink}
                to="/provider/dashboard/private-provider-network"
                activeStyle={ActiveNavLinkStyles}
                size="small"
                id={LinkNames.community}
              >
                Provider Community
              </Link>
            </NavigationLinkWrapper>
          )}

          {role === UserRoles.PROVIDER_BASIC && (
            <NavigationLinkWrapper>
              <ProButton onClick={onProButtonClick}>Pro</ProButton>
              <DisabledTitle size="small">Provider Community</DisabledTitle>
            </NavigationLinkWrapper>
          )}
          {role === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
            <NavigationLinkWrapper>
              {/* <ProButton onClick={onProButtonClick}>Pro</ProButton> */}
              <DisabledTitle size="small">Provider Community</DisabledTitle>
            </NavigationLinkWrapper>
          )}
          {role === UserRoles.PROVIDER_BASIC && (
            <NavigationLinkWrapper>
              <ProButton onClick={onProButtonClick}>Pro</ProButton>
              <DisabledTitle size="small">Provider Community</DisabledTitle>
            </NavigationLinkWrapper>
          )}
          <NavigationLinkWrapper>
            <Link
              as={ReactRouterLink}
              to="/provider/dashboard/settings"
              activeStyle={ActiveNavLinkStyles}
              size="small"
              id={LinkNames.account}
            >
              Account
            </Link>
          </NavigationLinkWrapper>
        </Navigation>
        {!isDesktop && <StyledRightIcon type="downArrow" width="18px" height="13px" onClick={handleRightArrowClick} />}
      </NavigationWrapper>
      {children}
    </ContentWrapper>
    <Suspense fallback={<div>Loading...</div>}>
      <Footer />
    </Suspense>
  </Wrapper>
);

export default ProviderDashboardLayout;
