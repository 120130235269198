import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

// custom components
import Icon from 'components/Icon';

// styled components
import { Flex, ColumnedFlex } from 'shared/styledComponents';
import { Text } from 'shared/typography';

// styles
import devices from 'theme/devices';
import fonts from 'theme/fonts';
import colors from 'theme/colors';

const CalendarIcon = styled(Icon)<any>`
  margin-left: 10px;
`;

const CalendarDownIcon = styled(Icon)<any>`
  cursor: pointer;
  margin-left: 10px;
  :hover {
    opacity: 0.7;
  }
`;

const StyledDate = styled(Text)(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumMedium};
    color: ${colors.bismark};
    margin-left: 15px;
  `,
);

const DateButtonContainer = styled(Flex)`
  align-items: center;
  cursor: pointer;
`;

const DateContainer = styled(ColumnedFlex)`
  align-items: flex-start;
  margin-bottom: 40px;

  .react-datepicker {
    font-family: ${fonts.wigrumLight};
    border-radius: 0px;
    border: 2px solid ${colors.primary};
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: 2px solid ${colors.primary};
    color: ${colors.primary};
  }

  .react-datepicker__day:hover {
    border-radius: 50%;
    background-color: ${colors.secondary};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${colors.primary};
    border-radius: 50%;
    outline: none;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected:hover {
    background-color: ${colors.primary};
  }

  .react-datepicker__triangle {
    border-bottom-color: ${colors.primary} !important;
  }

  .react-datepicker__navigation {
    outline: none;
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colors.primary};
  }
  .react-datepicker__navigation--previous {
    border-right-color: ${colors.primary};
  }
`;

const DatesContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const DateChip = styled.span`
  background-color: ${colors.pampas};
  font-family: ${fonts.wigrumLight};
  color: ${colors.primary};
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 50px;
  display: inline-block;
  border: none;
  width: 105px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
`;

const DateChipCloseIcon = styled(Icon)<any>`
  margin-left: 5px;
  cursor: pointer;
`;

const DateDisplay = styled.span`
  margin-top: 2px;
`;

const DateButton = ({ value, onClick }: any) => (
  <DateButtonContainer onClick={onClick}>
    <CalendarIcon type="calendar" width="20px" height="20px" />
    <StyledDate>{'Add Date'}</StyledDate>
    <CalendarDownIcon type="downArrow" width="15px" height="15px" />
  </DateButtonContainer>
);

interface UnavailabilityInputProps {
  unavailableDates: any;
  addUnavailableDate: (value: string) => void;
  removeUnavailableDate: (value: number) => void;
}

const UnavailabilityInput = ({
  unavailableDates,
  addUnavailableDate,
  removeUnavailableDate,
}: UnavailabilityInputProps): JSX.Element => {
  return (
    <>
      <DatesContainer>
        {unavailableDates?.map((date: any, idx: number) => (
          <DateChip key={date}>
            <DateDisplay>{moment(date).format('l')}</DateDisplay>
            <DateChipCloseIcon type="close" width="8px" height="8px" onClick={() => removeUnavailableDate(idx)} />
          </DateChip>
        ))}
      </DatesContainer>

      <DateContainer>
        <DatePicker
          minDate={new Date()}
          excludeDates={unavailableDates?.map((date: string) => new Date(date))}
          onChange={(date: any) => addUnavailableDate(date)}
          customInput={<DateButton />}
        />
      </DateContainer>
    </>
  );
};

export default UnavailabilityInput;
