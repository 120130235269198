export const convertProductValuesToDataModel = (productValues: any): any => ({
  price: productValues.price,
  description: productValues.description?.trim() || undefined,
  duration: productValues.duration || undefined,
  categoryId: productValues.categoryId || undefined,
  name: productValues.name || undefined,
  location: productValues.location || undefined,
  unavailableDates: productValues.unavailableDates || undefined,
});

export const convertAvailabilitiesToDataModel = (availabilities: any) => {
  const convertedAvailablities: any = {};

  for (const day in availabilities) {
    const validTimes: any = [];
    availabilities[day].forEach((time: any) => {
      // Check if we have integers represetning the start and end date
      if (Number.isInteger(time.start) && Number.isInteger(time.end)) {
        // Convert start time to UTC
        const localStartDate = new Date().setHours(time.start, 0, 0, 0);
        const utcStartDate = new Date(localStartDate).toISOString();
        // Convert end time to UTC
        const localEndDate = new Date().setHours(time.end, 0, 0, 0);
        const utcEndDate = new Date(localEndDate).toISOString();

        const convertedTime = {
          start: utcStartDate,
          end: utcEndDate,
        };
        validTimes.push(convertedTime);
      }
    });

    convertedAvailablities[day] = validTimes;
  }

  return convertedAvailablities;
};
