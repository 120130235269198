import {
  VALIDATION_ERRORS,
  EMAIL_FORMAT,
  PHONE_NUMBER_FORMAT,
  PASSWORD_FORMAT,
  WEBSITE_LINK_FORMAT,
  FACEBOOK_LINK_FORMAT,
  INSTAGRAM_LINK_FORMAT,
  LINKEDIN_LINK_FORMAT,
} from './types';

export const required = (value: any): string | undefined => (value ? undefined : VALIDATION_ERRORS.REQUIRED);

export const validateMinLength = (value: string, min: any): string | undefined =>
  value && value.length >= min ? undefined : `${VALIDATION_ERRORS.TOO_SHORT} ${min}`;

export const validateEmail = (value: any): string | undefined =>
  value && value.trim().match(EMAIL_FORMAT) ? undefined : VALIDATION_ERRORS.INVALID_EMAIL;

export const validatePasswordsConfirmation = (value: string, password: string): string | undefined =>
  value === password ? undefined : VALIDATION_ERRORS.PASSWORD_CONFIRMATION;

export const validatePhoneNumber = (value: any): string | undefined =>
  value && value.trim().match(PHONE_NUMBER_FORMAT) ? undefined : VALIDATION_ERRORS.INVALID_PHONE_NUMBER;

export const validatePasswordEquality = (value: any): string | undefined =>
  value && value.trim().match(PASSWORD_FORMAT) ? undefined : VALIDATION_ERRORS.INVALID_PASSWORD;

export const validateWebsiteLinkEquality = (value: any): string | undefined =>
  (value && value.trim().match(WEBSITE_LINK_FORMAT)) || !value ? undefined : VALIDATION_ERRORS.INVALID_LINK_FORMAT;

export const validateFacebookLinkEquality = (value: any): string | undefined =>
  (value && value.trim().match(FACEBOOK_LINK_FORMAT)) || !value ? undefined : VALIDATION_ERRORS.INVALID_LINK_FORMAT;

export const validateInstagramLinkEquality = (value: any): string | undefined =>
  (value && value.trim().match(INSTAGRAM_LINK_FORMAT)) || !value ? undefined : VALIDATION_ERRORS.INVALID_LINK_FORMAT;

export const validateLinkedinLinkEquality = (value: any): string | undefined =>
  (value && value.trim().match(LINKEDIN_LINK_FORMAT)) || !value ? undefined : VALIDATION_ERRORS.INVALID_LINK_FORMAT;
