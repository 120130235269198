// libraries
import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// custom components
import Icon from 'components/Icon';

// styled components
import { Link, ColumnedFlex, Flex, Frame } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';

// types
import { AppointmentSectionTypes } from '../types';

const Wrapper = styled.div`
  width: 100%;
`;

const Navigation = styled.div`
  margin-bottom: 40px;
  padding-bottom: 30px;
  border-bottom: ${({ theme: { colors } }): string => `1px solid ${colors.alto}`};
`;

const StyledLink = styled(Link)`
  margin-right: 50px;

  @media ${devices.mobile} {
    font-size: 18px;
    line-height: 23px;
  }
`;

const StyledIcon = styled(Icon)<any>`
  margin-right: 20px;
  ${({ isStepOpened }: any): string => (isStepOpened ? 'transform: rotate(180deg);' : '')}


  @media ${devices.mobile} {
    margin-right: 5px;
  }
`;

const ActiveNavLinkStyles = {
  opacity: '60%',
};

const StepsSection = styled(ColumnedFlex)`
  margin-bottom: 55px;

  @media ${devices.mobile} {
    margin-bottom: 0;
  }
`;

const StepWrapper = styled(ColumnedFlex)<any>`
  align-items: flex-start;
  width: 100%;
  height: fit-content;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid ${({ theme: { colors } }): string => colors.alto};
  transition: padding-bottom ease 0.3s;
`;

const StepHeadingWrapper = styled(Flex)<any>(
  ({ isStepOpened }: any) => `
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: ${isStepOpened ? '60px' : 0};

    @media ${devices.mobile} {
      margin-bottom: ${isStepOpened ? '35px' : '0'};
    }
  `,
);

const ContentWrapper = styled(ColumnedFlex)<any>(
  ({ isStepOpened, width }: any) => `
    width:  ${width ? width : '100%'};
    max-height: ${isStepOpened ? '2000px' : 0};
    transition: max-height ease 0.3s;
  `,
);

interface AppointmentsLayoutProps {
  children: ReactNode;
  isDesktop: boolean;
  openedSectionType: any;
  handleSectionTypeClick: any;
  handleRedirect: (url: string) => () => void;
}

const AppointmentsLayout = ({
  isDesktop,
  children,
  openedSectionType,
  handleSectionTypeClick,
  handleRedirect,
}: AppointmentsLayoutProps): JSX.Element =>
  isDesktop ? (
    <Wrapper>
      <Navigation>
        <StyledLink
          as={NavLink}
          to="/provider/dashboard/appointments/products-offered"
          activeStyle={ActiveNavLinkStyles}
          size="small"
        >
          Products Offered
        </StyledLink>
        <StyledLink
          as={NavLink}
          to="/provider/dashboard/appointments/availability"
          activeStyle={ActiveNavLinkStyles}
          size="small"
        >
          Input Availability
        </StyledLink>
        <StyledLink
          as={NavLink}
          to="/provider/dashboard/appointments/upcoming-appointments"
          activeStyle={ActiveNavLinkStyles}
          size="small"
        >
          Upcoming Appointments
        </StyledLink>
        <StyledLink
          as={NavLink}
          to="/provider/dashboard/products/payment-info"
          activeStyle={ActiveNavLinkStyles}
          size="small"
        >
          Payment Info
        </StyledLink>
      </Navigation>
      {children}
    </Wrapper>
  ) : (
    <Wrapper>
      <StepsSection>
        <Frame>
          <StepWrapper>
            <StepHeadingWrapper
              isStepOpened={openedSectionType === AppointmentSectionTypes.ProductsOffered}
              onClick={handleSectionTypeClick(AppointmentSectionTypes.ProductsOffered)}
            >
              <StyledLink
                as={NavLink}
                to="/provider/dashboard/appointments/products-offered"
                activeStyle={ActiveNavLinkStyles}
                size="small"
              >
                Products Offered
              </StyledLink>
              <StyledIcon
                type="downArrow"
                width="17px"
                height="13px"
                isStepOpened={openedSectionType === AppointmentSectionTypes.ProductsOffered}
                onClick={handleRedirect('/provider/dashboard/appointments/products-offered')}
              />
            </StepHeadingWrapper>
            {openedSectionType === AppointmentSectionTypes.ProductsOffered && (
              <ContentWrapper isStepOpened={openedSectionType === AppointmentSectionTypes.ProductsOffered}>
                {children}
              </ContentWrapper>
            )}
          </StepWrapper>
          <StepWrapper>
            <StepHeadingWrapper
              isStepOpened={openedSectionType === AppointmentSectionTypes.InputAvailability}
              onClick={handleSectionTypeClick(AppointmentSectionTypes.InputAvailability)}
            >
              <StyledLink
                as={NavLink}
                to="/provider/dashboard/appointments/availability"
                activeStyle={ActiveNavLinkStyles}
                size="small"
              >
                Input Availability
              </StyledLink>
              <StyledIcon
                type="downArrow"
                width="17px"
                height="13px"
                isStepOpened={openedSectionType === AppointmentSectionTypes.InputAvailability}
                onClick={handleRedirect('/provider/dashboard/appointments/availability')}
              />
            </StepHeadingWrapper>
            {openedSectionType === AppointmentSectionTypes.InputAvailability && (
              <ContentWrapper isStepOpened={openedSectionType === AppointmentSectionTypes.InputAvailability}>
                {children}
              </ContentWrapper>
            )}
          </StepWrapper>
          <StepWrapper>
            <StepHeadingWrapper
              isStepOpened={openedSectionType === AppointmentSectionTypes.UpcomingAppointments}
              onClick={handleSectionTypeClick(AppointmentSectionTypes.UpcomingAppointments)}
            >
              <StyledLink
                as={NavLink}
                to="/provider/dashboard/appointments/upcoming-appointments"
                activeStyle={ActiveNavLinkStyles}
                size="small"
              >
                Upcoming Appointments
              </StyledLink>
              <StyledIcon
                type="downArrow"
                width="17px"
                height="13px"
                isStepOpened={openedSectionType === AppointmentSectionTypes.UpcomingAppointments}
                onClick={handleRedirect('/provider/dashboard/appointments/upcoming-appointments')}
              />
            </StepHeadingWrapper>
            {openedSectionType === AppointmentSectionTypes.UpcomingAppointments && (
              <ContentWrapper isStepOpened={openedSectionType === AppointmentSectionTypes.UpcomingAppointments}>
                {children}
              </ContentWrapper>
            )}
          </StepWrapper>

          <StepWrapper>
            <StepHeadingWrapper
              isStepOpened={openedSectionType === AppointmentSectionTypes.Payment}
              onClick={handleSectionTypeClick(AppointmentSectionTypes.Payment)}
            >
              <StyledLink
                as={NavLink}
                to="/provider/dashboard/products/payment-info"
                activeStyle={ActiveNavLinkStyles}
                size="small"
              >
                Payment Info
              </StyledLink>
              <StyledIcon
                type="downArrow"
                width="17px"
                height="13px"
                isStepOpened={openedSectionType === AppointmentSectionTypes.Payment}
                onClick={handleRedirect('/provider/dashboard/products/payment-info')}
              />
            </StepHeadingWrapper>
            {openedSectionType === AppointmentSectionTypes.Payment && (
              <ContentWrapper isStepOpened={openedSectionType === AppointmentSectionTypes.Payment}>
                {children}
              </ContentWrapper>
            )}
          </StepWrapper>
        </Frame>
      </StepsSection>
    </Wrapper>
  );

export default AppointmentsLayout;
