// libraries
import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

// pages
import PersonalDetailsContainer from 'pages/ClientDashboard/SubPages/PersonalDetails';
import UpcomingAppointments from 'pages/ClientDashboard/SubPages/UpcomingAppointments';
import PaymentMethod from 'pages/ClientDashboard/SubPages/PaymentMethod';
import Settings from 'pages/ClientDashboard/SubPages/Settings';

const CLIENT_URLS = [
  '/client/dashboard/personal-details',
  '/client/dashboard/upcoming-appointments',
  '/client/dashboard/settings',
];

interface ClientRoutesProps {
  isLoggedIn: boolean;
}

const ClientRoutes = ({ isLoggedIn }: ClientRoutesProps): JSX.Element => {
  const location = useLocation();

  return (
    <>
      {!isLoggedIn && CLIENT_URLS.includes(location.pathname) && (
        <Redirect
          to={{
            pathname: '/login',
            state: { locationRedirectPath: location.pathname, locationRedirectSearch: location.search },
          }}
        />
      )}

      <Route path="/client/dashboard/personal-details" component={PersonalDetailsContainer} />
      <Route path="/client/dashboard/upcoming-appointments" component={UpcomingAppointments} />
      <Route path="/client/dashboard/payment-method" component={PaymentMethod} />
      <Route path="/client/dashboard/settings" component={Settings} />
    </>
  );
};

export default ClientRoutes;
