// libraries
import React from 'react';
import styled from 'styled-components';
import Product from './Product';

// styled components
import { PrimaryButton } from 'shared/styledComponents';
import { Title, Text } from 'shared/typography';

// hooks
import useResize from 'hooks/useResize';

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Heading = styled(Title)`
  margin-bottom: 25px;
`;

const NoProductsFallback = styled(Text)`
  margin-top: 10px;
  margin-bottom: 30px;
`;

interface MyProductsProps {
  handleAddProductButton: (value: boolean) => void;
  products: any[];
  handleEditProductClick: (value: any) => void;
  handleDeleteProductClick: (value: any) => void;
}

const MyProducts = ({
  products,
  handleAddProductButton,
  handleEditProductClick,
  handleDeleteProductClick,
}: MyProductsProps): JSX.Element => {
  const { isDesktop } = useResize();
  return (
    <>
      {isDesktop && <Heading>My Products</Heading>}
      <ProductsContainer>
        {products.length ? (
          products.map((product: any, idx: number) => (
            <Product
              key={`${idx}_${product.name}`}
              product={product}
              onEditProductClick={handleEditProductClick}
              onDeleteProductClick={handleDeleteProductClick}
            />
          ))
        ) : (
          <NoProductsFallback>You have not yet added any products.</NoProductsFallback>
        )}
      </ProductsContainer>
      <PrimaryButton onClick={() => handleAddProductButton(true)}>Add Product</PrimaryButton>
    </>
  );
};

export default MyProducts;
