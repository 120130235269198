import React from 'react';
import NumberFormat from 'react-number-format';

const PriceDisplay = (props: any) => {
  return (
    <NumberFormat
      {...props}
      value={props.value}
      prefix="$"
      thousandSeparator
      fixedDecimalScale={true}
      allowNegative={false}
      displayType="text"
    />
  );
};

export default PriceDisplay;
