// libraries
import React from 'react';
import { useSelector } from 'react-redux';

// custom components
import Modals from './Modals';

// selectors
import { selectModalTitle, selectModalData } from 'components/Modals/selectors';

const ModalsContainer = (): JSX.Element => {
  const title = useSelector(selectModalTitle);
  const data = useSelector(selectModalData);

  return <Modals title={title} data={data} />;
};

export default ModalsContainer;
