// libraries
import React, { useEffect } from 'react';
import styled from 'styled-components';

// styled components
import { CentredFlex } from 'shared/styledComponents';

// custom components
import LogInOrCreateAccount from 'modules/LogInOrCreateAccount';
import ResetPasswordModal from 'modules/ResetPassword';
import ForgotPasswordModal from 'modules/ForgotPassword';
import ConformationModal from 'components/Modals/ConfirmationModal';
import NotificationModal from './NotificationModal';
import EmailPopupForm from 'modules/EmailPopupForm';

//styles
import devices from 'theme/devices';

const Wrapper = styled(CentredFlex)<any>`
  display: ${({ isOpen }): string => (isOpen ? 'flex' : 'none')};
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 9999;

  @media ${devices.mobile} {
    align-items: start;
    overflow-y: auto;
  }
`;

interface ModalProps {
  title: string;
  data?: any;
}

const Modals = ({ title, data }: ModalProps): JSX.Element => {
  document.body.style.overflow = 'hidden';
  document.body.style.marginRight = '15px';

  useEffect(
    (): any => (): any => {
      document.body.style.overflow = '';
      document.body.style.marginRight = '';
    },
    [],
  );
  return (
    <Wrapper isOpen={!!title}>
      {((): JSX.Element | null => {
        switch (title) {
          case 'logInOrCreateAccount':
            return <LogInOrCreateAccount {...data} />;
          case 'forgotPasswordModal':
            return <ForgotPasswordModal />;
          case 'resetPasswordModal':
            return <ResetPasswordModal />;
          case 'conformationModal':
            return <ConformationModal {...data} />;
          case 'notificationModal':
            return <NotificationModal {...data} />;
          case 'deleteProductModal':
            return <ConformationModal {...data} />;
          case 'emailPopupModal':
            return <EmailPopupForm {...data} />;
          default:
            return null;
        }
      })()}
    </Wrapper>
  );
};

export default Modals;
