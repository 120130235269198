// libraries
import React from 'react';
import { Route } from 'react-router-dom';

// pages
const Home = React.lazy(() => import('pages/Home'));
const Login = React.lazy(() => import('pages/Login'));
const Service = React.lazy(() => import('pages/Service'));
const Booking = React.lazy(() => import('pages/Booking'));
const ProviderOnboardingComplete = React.lazy(() => import('pages/ProviderOnboardingComplete'));
const ClientOnboarding = React.lazy(() => import('pages/ClientOnboarding'));
const ProviderProfile = React.lazy(() => import('pages/ProviderProfile'));
const ForProvidersLanding = React.lazy(() => import('pages/ForProvidersLanding'));
const FindProviders = React.lazy(() => import('pages/FindProviders'));
const Article = React.lazy(() => import('pages/Articles'));
const CreateStory = React.lazy(() => import('pages/RealStory/components/CreateStory'));
const RealStory = React.lazy(() => import('pages/RealStory'));
const DmcaCopyrightPolicy = React.lazy(() => import('pages/DmcaCopyrightPolicy'));
const NonCoveredEntityCertification = React.lazy(() => import('pages/NonCoveredEntityCertification'));
const HipaaAuthorization = React.lazy(() => import('pages/HipaaAuthorization'));
const VerifyClientEmail = React.lazy(() => import('pages/VerifyClientEmail'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));
const TermsOfUse = React.lazy(() => import('pages/TermsOfUse'));
const ProviderAgreement = React.lazy(() => import('pages/ProviderAgreement'));
const RealStories = React.lazy(() => import('pages/RealStories'));
const Classes = React.lazy(() => import('pages/Classes'));

const CommonRoutes = (): JSX.Element => (
  <>
    <Route path="/login" component={Login} />
    <Route path="/verify-email" component={VerifyClientEmail} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route path="/terms-of-use" component={TermsOfUse} />
    <Route path="/provider-agreement" component={ProviderAgreement} />
    <Route path="/hipaa-authorization" component={HipaaAuthorization} />
    <Route path="/non-covered-entity-certification" component={NonCoveredEntityCertification} />
    <Route path="/dmca-copyright-policy" component={DmcaCopyrightPolicy} />

    <Route exact path="/" component={Home} />
    <Route path="/journey/:url" component={RealStory} />
    <Route path="/share-your-journey" component={CreateStory} />
    <Route path="/learn/:url" component={Article} />
    <Route path="/for-providers" component={ForProvidersLanding} />
    <Route path="/journeys" component={RealStories} />
    <Route path="/classes" component={Classes} />

    <Route path="/create-provider-account/complete" component={ProviderOnboardingComplete} />
    <Route path="/create-new-password" component={ClientOnboarding} />
    <Route path="/find-providers" component={FindProviders} />
    <Route path="/maternal-specialist/:slug" component={ProviderProfile} />
    <Route path="/service/:name" component={Service} />
    <Route path="/booking" component={Booking} />
  </>
);

export default CommonRoutes;
